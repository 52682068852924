<template>
  <layout>
    <PageHeader :title="title" ref="pageForm"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <vs-input
                    v-model="filterData.fromDate"
                    type="date"
                  ></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <vs-input v-model="filterData.toDate" type="date"></vs-input>
                </label>
              </div>
              <div class="col-sm-12 col-md-12 d-flex justify-content-start">
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content"
                >
                  Reporte de:
                </label>
                <select
                  v-model="filterData.typeOfLogs"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in typeOfLogs"
                    :key="index"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div
                class="col-sm-12 col-md-12 d-flex justify-content-start"
                v-if="filterData.typeOfLogs === logsTypes.roulette"
              >
                <vs-checkbox v-model="filterData.localStorage">
                  VER LOGS EN LOCAL STORAGE
                </vs-checkbox>
              </div>
              <div
                class="col-sm-12 col-md-12 d-flex justify-content-start"
                v-if="filterData.typeOfLogs === logsTypes.boilerplate"
              >
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content"
                >
                  Tipo de error:
                </label>
                <select
                  v-model="filterData.typeError"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in errorTypes"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-button
                type="button"
                @click="filtrar"
                :disabled="!filterData.typeOfLogs"
              >
                Filtrar
              </vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length"
              >
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t('helpers.loading') }}...
            </div>
            <div class="row mt-2" v-if="tableData.length">
              <div class="col-sm-12 col-md-6 d-inline-flex align-items-center">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0 mt-4" v-if="tableData.length">
                <b-table
                  id="transaction-table"
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                >
                  <!-- REQUEST -->
                  <template #cell(request)="data">
                    <details>
                      <summary>{{ $t('helpers.look') }} request</summary>
                      <pre v-if="typeof data.item.response !== 'string'">
                        <code>
                          {{
                            typeof data.item.request === "string"
                            ? JSON.stringify(JSON.parse(data.item.request), null, 2)

                            : data.item.request
                          }}
                        </code>
                      </pre>
                    </details>
                  </template>
                  <!-- REQUEST -->
                  <template #cell(error)="data">
                    <details>
                      <summary>{{ $t('helpers.look') }} error</summary>
                      <pre v-if="typeof data.item.response !== 'string'">
                        <code>
                          {{
                            data.item.error
                            ? data.item.error
                            : "NO ERROR"
                          }}
                        </code>
                      </pre>
                    </details>
                  </template>
                  <!-- RESPONSE -->
                  <template #cell(reponse)="data">
                    <pre v-if="typeof data.item.response !== 'string'">
                      <code>
                        {{
                          typeof data.item.response === "string"
                          ? JSON.stringify(JSON.parse(data.item.response), null, 2)
                          : data.item.response
                        }}
                      </code>  
                    </pre>
                  </template>
                  <!-- DATE -->
                  <template #cell(createdAt)="data">
                    <div
                      class="createdAt"
                      @mouseover="handleOver"
                      @mouseleave="handleLeave"
                    >
                      {{
                        moment(data.item.createdAt).format(
                          'DD/MM/YYYY | HH:mm:ss'
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill"
                        >Server time:
                        {{
                          moment
                            .utc(data.item.createdAt)
                            .format('DD/MM/YYYY | HH:mm:ss')
                        }}</span
                      >
                    </div>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <!-- PAG -->
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length > limit"
                    ></button>
                  </ul>
                  <!-- <pagination :totalPages="totalPages" :currentPage="currentPage"></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header-simple';
import { LOGS, errorTypes } from '../../../../state/modules/logs';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BetLogs',
  components: { Layout, PageHeader },
  data() {
    return {
      title: this.$t('betLogs.title'),
      filterData: {
        localStorage: false,
      },
      fileName: this.$t('betLogs.title'),
      fetching: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'createdAt',
      sortDesc: true,
      tableData: [],
      // FIELDS TABLE - XLS
      json_fields: {},
      fields: [
        {
          key: 'request',
          label: 'REQUEST',
          class: 'space-nowrap text-start',
        },
        {
          key: 'error',
          label: 'ERROR',
          class: 'space-nowrap text-start',
        },
        {
          key: 'response',
          label: 'RESPONSE',
          class: 'space-nowrap text-start',
        },
        {
          key: 'createdAt',
          label: this.$t('table.data').toUpperCase(),
          class: 'space-nowrap text-start',
        },
      ],
      // FIELDS TABLE - XLS
      loading: false,
      // PAG
      currentPage: 1,
      skip: 0,
      limit: 10,
      page: 0,
      logsTypes: LOGS,
      errorTypes,
      typeOfLogs: [
        {
          name: LOGS.roulette,
        },
        {
          name: LOGS.boilerplate,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      mapLogs: 'logs/getLogs',
    }),
  },
  methods: {
    async getData() {
      this.loading = true;

      const params = {
        ...this.filterData,
        limit: this.limit,
        page: this.currentPage,
      };

      await this.fetchLogs({ params, type: this.filterData.typeOfLogs });

      this.tableData = this.mapLogs;

      this.loading = false;
    },
    exportData() {},
    async filtrar() {
      if (this.filterData.localStorage) {
        await this.getLogsFromLocalStorage({
          limit: this.limit,
          page: this.currentPage,
        });
        this.tableData = this.mapLogs;
      } else {
        this.getData();
      }
    },
    cancelFilter() {
      this.currentPage = 1;

      this.filterData = {
        localStorage: false,
      };
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filtrar();
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
    ...mapActions({
      fetchLogs: 'logs/fetchLogs',
      getLogsFromLocalStorage: 'logs/getLogsFromLocalStorage',
    }),
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
  },
};
</script>

<style scoped>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}
</style>
